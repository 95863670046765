<div class="modal-header">
  <p
    class="si-title-1 mt-9 mb-0"
    id="modal-title"
    *ngIf="entityToDelete === 'floor'"
  >
    {{
      'PLANNING.PHYSICAL_ENVIRONMENT.RELOCATE_DEVICES_FLOOR.TITLE' | translate
    }}
  </p>
  <p
    class="si-title-1 mt-9 mb-0"
    id="modal-title"
    *ngIf="entityToDelete === 'location'"
  >
    {{
      'PLANNING.PHYSICAL_ENVIRONMENT.RELOCATE_DEVICES_ROOM.TITLE' | translate
    }}
  </p>
  <p
    class="si-title-1 mt-9 mb-0"
    id="modal-title"
    *ngIf="entityToDelete === 'borderRouter'"
  >
    {{ 'PLANNING.PHYSICAL_ENVIRONMENT.RELOCATE_ACTUATORS.TITLE' | translate }}
  </p>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    [attr.data-testid]="'close-btn'"
    (click)="onClickCloseModal()"
  ></button>
</div>
<div class="modal-body">
  <p class="si-body-1 mt-4 mb-0">
    <span
      *ngIf="entityToDelete === 'floor'"
      [innerHtml]="
        'PLANNING.PHYSICAL_ENVIRONMENT.RELOCATE_DEVICES_FLOOR.MESSAGE_WITH_LINK'
          | translate
            : {
                link:
                  '<a href=' +
                  linkToCommissioning +
                  '><b>' +
                  ('BUILDING.RELOCATE_DEVICES.ANCHOR_TEXT_LINK' | translate) +
                  '</b></a>'
              }
      "
    ></span>
    <span
      *ngIf="entityToDelete === 'location'"
      [innerHtml]="
        'PLANNING.PHYSICAL_ENVIRONMENT.RELOCATE_DEVICES_ROOM.MESSAGE_WITH_LINK'
          | translate
            : {
                link:
                  '<a href=' +
                  linkToCommissioning +
                  '><b>' +
                  ('BUILDING.RELOCATE_DEVICES.ANCHOR_TEXT_LINK' | translate) +
                  '</b></a>'
              }
      "
    ></span>
    <span
      *ngIf="entityToDelete === 'borderRouter'"
      [innerHtml]="
        'PLANNING.PHYSICAL_ENVIRONMENT.RELOCATE_ACTUATORS.MESSAGE_WITH_LINK'
          | translate
            : {
                link:
                  '<a href=' +
                  linkToCommissioning +
                  '><b>' +
                  ('BUILDING.RELOCATE_DEVICES.ANCHOR_TEXT_LINK' | translate) +
                  '</b></a>'
              }
      "
    ></span>
  </p>
</div>
