import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SiAddressModule, SiSharedModule } from '@building-x/common-ui-ng';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  SI_DATATABLE_CONFIG,
  SiCardModule,
  SiDatatableModule,
  SiLandingPageModule,
  SiLanguageSwitcherModule,
  SiNavbarModule,
} from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { localStorageSync } from 'ngrx-store-localstorage';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ModalModule } from 'ngx-bootstrap/modal';

import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActiveProjectModule } from './shared/active-project/active-project.module';
import { AuthModule } from './shared/auth/auth.module';
import { BackdropModule } from './shared/backdrop/backdrop.module';
import { CustomMultiTranslateHttpLoader } from './shared/i18n/custom-multi-translate-http-loader';
import { CorrelationIdInterceptor } from './shared/interceptors/correlation-id.interceptor';
import { PendingRequestsModule } from './shared/pending-requests/pending-requests.module';
import { LcasRouteReuseStrategy } from './shared/routing/lcas-route.reuse-strategy';
import { SharedModule } from './shared/shared.module';
import { SharedFormlyModule } from './shared/formly/shared-formly.module';

export function createTranslateLoader(
  http: HttpBackend,
): MultiTranslateHttpLoader {
  return new CustomMultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json', optional: false },
    { prefix: './assets/i18n/horizon-ui-ng/imported', suffix: `.json` },
    {
      prefix: './assets/i18n/horizon-ui-ng/no-translations',
      suffix: `.json`,
    },
    {
      prefix: './assets/i18n/horizon-ui-ng/imported/no-translations',
      suffix: `.json`,
    },
    {
      prefix: 'bt_disciplines',
      optional: true,
    },
    {
      prefix: 'bt_equipment_types',
      optional: true,
    },
    {
      prefix: 'bt_items',
      optional: true,
    },
    {
      prefix: 'bt_units',
      optional: true,
    },
    {
      prefix: 'bt_units.symbols',
      optional: true,
    },
  ]);
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return localStorageSync({ keys: ['auth', 'tutorial'], rehydrate: true })(
    reducer,
  );
}

const metaReducers: MetaReducer[] = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    SiLandingPageModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    SiNavbarModule,
    SharedModule,
    SiCardModule,
    SiSharedModule,
    AuthModule,
    SiLanguageSwitcherModule,
    ActiveProjectModule,
    PendingRequestsModule,
    BackdropModule,
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot([]),
    SiAddressModule.forRoot({
      googleConfig: {
        apiKey: environment.googleMapsApiKey,
        libraries: ['places'],
        language: localStorage.lang || 'template',
      },
      isChinaDeployment: environment.isChinaDeployment === 'true',
    }),
    ModalModule.forRoot(),
    SiTranslateNgxTModule,
    SharedFormlyModule,
    NgxDatatableModule.forRoot(SI_DATATABLE_CONFIG),
    SiDatatableModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationIdInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: LcasRouteReuseStrategy,
    },
    provideAnimationsAsync(navigator.webdriver ? 'noop' : 'animations'),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
