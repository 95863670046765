import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, isEqual } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, Subject } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';

import { SetupFormModel } from '../../api-client/services/lcas-api-client/models/setup-form.model';
import { SetupFormItem } from '../../models/business/setup-form-item.model';
import { FormlySchemaConverterService } from '../formly-schema-converter.service';
import { TypedSetupFromHelperService } from './interfaces/typed-setup-form-helper-service.interface';
import { SetupFormOutputService } from './services/setup-form-output.service';

@Component({
  selector: 'app-setup-form',
  templateUrl: './setup-form.component.html',
  styleUrls: ['./setup-form.component.scss'],
})
export class SetupFormComponent implements OnInit, OnDestroy {
  private navigatedByReloadingRoute = false;

  @Input() formItem: SetupFormItem | undefined;

  @Input() isModal: boolean | undefined;

  @Input() output?: Subject<SetupFormModel | undefined>;

  form = new FormGroup({});

  fields$?: Observable<{ config: FormlyFieldConfig[]; model: SetupFormModel }>;

  initialModel: SetupFormModel | undefined = undefined;

  schedulerName: string | undefined = undefined;

  private cdRef = inject(ChangeDetectorRef);

  isSchedulerLoading$: Observable<boolean> =
    this.setupFormOutputService.isSchedulerLoading$.asObservable();

  constructor(
    public bsModalRef: BsModalRef,
    private route: ActivatedRoute,
    private location: Location,
    private formlySchemaConverterService: FormlySchemaConverterService,
    private setupFormOutputService: SetupFormOutputService,
    @Inject('typedSetupFormHelper')
    private typedSetupFromHelperService: TypedSetupFromHelperService,
  ) {
    this.cdRef.markForCheck();
  }

  ngOnInit(): void {
    this.fields$ = of(this.isModal).pipe(
      mergeMap((isModal) =>
        isModal
          ? of(undefined)
          : this.route.paramMap.pipe(
              mergeMap((paramMap) =>
                this.getSetupFormConfig(
                  paramMap.get('id')!,
                  window.history.state.config,
                ),
              ),
              tap((config) => {
                this.getSchedulerName(config.id);
                this.formItem = config;
                this.initialModel = cloneDeep(this.formItem?.config?.model);
                this.output = this.setupFormOutputService.routedSetupFormOutput;
              }),
            ),
      ),
      mergeMap(() => this.getFields()),
    );
  }

  ngOnDestroy(): void {
    this.output?.next(undefined);
  }

  submit(model: SetupFormModel) {
    if (!isEqual(this.initialModel, model)) {
      this.output?.next(model);
    }
    this.output?.next(undefined);
    this.close();
  }

  close(): void {
    this.output?.next(undefined);
    if (this.isModal) {
      this.bsModalRef.hide();
    } else if (this.navigatedByReloadingRoute) {
      this.typedSetupFromHelperService.navigateOnClose(this.route);
    } else {
      this.location.back();
    }
  }

  private getSetupFormConfig(
    id: string,
    config?: SetupFormItem,
  ): Observable<SetupFormItem> {
    return of(!config || config.id !== id).pipe(
      tap((shouldLoad) => {
        this.navigatedByReloadingRoute = shouldLoad;
      }),
      mergeMap((shouldLoad) =>
        shouldLoad
          ? this.typedSetupFromHelperService.getSetupFormItem(id).pipe(
              map((item) => ({
                id,
                config: item.config,
                eTag: item.eTag,
              })),
            )
          : of(config!),
      ),
    );
  }

  private getFields(): Observable<{
    config: FormlyFieldConfig[];
    model: SetupFormModel;
  }> {
    return this.formlySchemaConverterService
      .getFieldConfig(
        this.formItem!.config!.schema,
        this.formItem!.config!.definitions,
      )
      .pipe(
        map((config) => {
          const configWithoutHiddenFields = config
            .map((model) => {
              return {
                ...model,
                fieldGroup:
                  model.type === 'scan'
                    ? model.fieldGroup
                    : model.fieldGroup?.filter(
                        (fg) => fg.props?.hidden !== true,
                      ),
              };
            })
            .filter((model) => model.fieldGroup?.length);
          return {
            config: configWithoutHiddenFields,
            model: { ...this.formItem!.config!.model },
          };
        }),
      );
  }

  private getSchedulerName(id: string): void {
    this.typedSetupFromHelperService
      .getSetupFormItem(id)
      .pipe(first())
      .subscribe((item) => {
        this.schedulerName = item.name;
      });
  }
}
